import { AssignmentQuestion } from "modules/questions/question.types";
import {
  AcceptableAnswersEditor,
  isAcceptableAnswerType,
} from "./AcceptableAnswersEditor";
import { AnswerChoicesEditor } from "./AnswerChoicesEditor";

export default function AnswersEditor({
  item,
}: {
  item: AssignmentQuestion["question"];
}) {
  if (isAcceptableAnswerType(item.questionContent.type)) {
    return <AcceptableAnswersEditor item={item} />;
  }

  return <AnswerChoicesEditor item={item} />;
}
