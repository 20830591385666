import { Box, Text } from "@mantine/core";
import { AssignmentQuestion } from "modules/questions/question.types";
import { Editor } from "~/components/Editor/Editor";
import AnswersEditor from "~/routes/teacher.assignments.$assignmentId_.edit/AnswersEditor";

interface QuestionContentEditorProps {
  question: AssignmentQuestion["question"];
  onContentChange: (
    value: string,
    field: "questionText" | "explanation",
  ) => void;
}

export function QuestionContentEditor({
  question,
  onContentChange,
}: QuestionContentEditorProps) {
  return (
    <Box className="flex flex-col h-full">
      <Box className="pb-2">
        <Text size="sm" className="pb-1 font-semibold">
          Question
        </Text>
        <Editor
          initialContent={question.questionContent.questionText}
          onChange={(value) => onContentChange(value, "questionText")}
        />
      </Box>
      <AnswersEditor item={question} />
      <Box className="pb-2">
        <Text size="sm" className="pb-1 font-semibold">
          Explanation
        </Text>
        <Editor
          initialContent={question.questionContent.explanation ?? ""}
          onChange={(value) => onContentChange(value, "explanation")}
        />
      </Box>
    </Box>
  );
}
