import {
  Box,
  Button,
  CloseButton,
  Input,
  Stack,
  TextInput,
} from "@mantine/core";
import { showErrorToast } from "app/components/Notifications/customNotifications";
import {
  AssignmentQuestion,
  QuestionType,
} from "modules/questions/question.types";
import { useCallback, useEffect, useState } from "react";
import { TbPlus } from "react-icons/tb";
import { useDebounceFetcher } from "remix-utils/use-debounce-fetcher";
import { action } from "../api.questions.$questionId.updateAcceptableAnswers";

const ACCEPTABLE_ANSWER_TYPES = [
  QuestionType.SHORT_ANSWER,
  QuestionType.CLOZE,
] as const;

type AcceptableAnswerType = (typeof ACCEPTABLE_ANSWER_TYPES)[number];

type AcceptableAnswerContent = Extract<
  AssignmentQuestion["question"]["questionContent"],
  { type: AcceptableAnswerType }
>;

export const isAcceptableAnswerType = (
  type: QuestionType,
): type is AcceptableAnswerType =>
  ACCEPTABLE_ANSWER_TYPES.includes(type as AcceptableAnswerType);

export function AcceptableAnswersEditor({
  item,
}: {
  item: AssignmentQuestion["question"];
}) {
  const fetcher = useDebounceFetcher<typeof action>();
  const [answers, setAnswers] = useState<string[]>(() => {
    const content = item.questionContent;
    if (isAcceptableAnswerType(content.type)) {
      const typedContent = content as AcceptableAnswerContent;
      return typedContent.acceptableAnswers;
    }
    return [];
  });

  useEffect(() => {
    if (fetcher.state === "idle" && fetcher.data && "error" in fetcher.data) {
      showErrorToast(`Failed to update answers: ${fetcher.data.error}`);
      // Reset to the last known good state from the backend
      const content = item.questionContent;
      if (isAcceptableAnswerType(content.type)) {
        const typedContent = content as AcceptableAnswerContent;
        setAnswers(typedContent.acceptableAnswers);
      }
    }
  }, [fetcher.state, fetcher.data, item]);

  const updateBackend = useCallback(
    (newAnswers: string[], debounceTimeout?: number) => {
      const content = item.questionContent as AcceptableAnswerContent;
      fetcher.submit(
        JSON.stringify({
          type: content.type,
          acceptableAnswers: newAnswers,
        }),
        {
          method: "POST",
          action: `/api/questions/${item.id}/updateAcceptableAnswers`,
          encType: "application/json",
          debounceTimeout: debounceTimeout ?? 1000,
        },
      );
    },
    [fetcher, item],
  );

  const handleAnswerChange = useCallback(
    (
      index: number,
      value: string | undefined,
      remove?: boolean,
      debounceTimeout?: number,
    ) => {
      setAnswers((prevAnswers) => {
        const newAnswers = [...prevAnswers];
        if (remove) {
          newAnswers.splice(index, 1);
        } else if (value !== undefined) {
          if (index === newAnswers.length) {
            newAnswers.push(value);
          } else {
            newAnswers[index] = value;
          }
        }
        updateBackend(newAnswers, debounceTimeout);
        return newAnswers;
      });
    },
    [updateBackend],
  );

  const addAnswer = useCallback(() => {
    handleAnswerChange(answers.length, "", false, 0);
  }, [answers.length, handleAnswerChange]);

  const content = item.questionContent;
  if (!isAcceptableAnswerType(content.type)) {
    return null;
  }

  return (
    <Input.Wrapper label="Acceptable Answers" className="pb-2">
      <Stack className="pl-2 pt-1">
        {answers.map((answer, index) => (
          <Box key={index} className="flex gap-2 w-full items-center">
            <TextInput
              value={answer}
              className="w-full"
              onChange={(e) => handleAnswerChange(index, e.target.value)}
            />
            <CloseButton
              onClick={() => handleAnswerChange(index, undefined, true, 0)}
            />
          </Box>
        ))}
      </Stack>
      <Button
        variant="transparent"
        color="primary"
        className="p-1"
        onClick={addAnswer}
      >
        <TbPlus color="#475569" size={16} /> &nbsp; Add answer
      </Button>
    </Input.Wrapper>
  );
}
